import React, { useEffect } from "react";
import Container from "../components/Container";
import ArticleHeroSection from "../components/ArticleHeroSection";
import Breadcrumb from "../components/Breadcrumb";
import ArticleContent from "../components/ArticleContent";
import RelatedArticles from "../components/RelatedArticles";
import { useGetPostBySlugQuery } from "../features/api/api-slice";
import AppTitle from "../components/AppTitle";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArticleContentSkeleton from "../components/ArticleContentSkeleton";
import { scrollTo } from "../assets/js/helpers";
import { Helmet } from "react-helmet-async";
import SEO from "../components/SEO";

const SingleArticle = () => {
  // const { t, i18n } = useTranslation('common');
  const { t, i18n } = useTranslation();
  const params = useParams();
  const {
    data: postData,
    isSuccess,
    isLoading,
    isFetching,
  } = useGetPostBySlugQuery(params.articleSlug, i18n.language);
  const post = postData?.data && postData.data;
  const relatedCategoryLink = isSuccess && `/${postData.data.category.slug}`;
  const hierarchy = [
    {
      id: 1,
      label: t("app.appName"),
      link: "/",
      clickable: true,
    },
    {
      id: 2,
      label: isSuccess && postData.data.category.title,
      link: `/${params.categorySlug}`,
      clickable: true,
    },
    {
      id: 3,
      label: isSuccess && postData.data.title,
      clickable: false,
    },
  ];

  useEffect(() => {
    scrollTo();
  });

  return (
    <>
      {(isLoading || isFetching) && <ArticleContentSkeleton />}
      {isSuccess && postData.data && (
        <>
          <AppTitle>{postData.data.title}</AppTitle>
          <SEO
            link={document.URL}
            description={post.snippet}
            title={post.title}
            image={post.coverImage}
          />
          <div>
            <Container>
              <Breadcrumb breadcrumbHierarchy={hierarchy} />
            </Container>
          </div>
          <section className="">
            <Container padding={"pb-0"}>
              <ArticleHeroSection
                postExcerpt={postData.data}
                categoryLink={relatedCategoryLink}
              />
            </Container>

            <Container>
              <div className="relative">
                <ArticleContent
                  categoryLink={relatedCategoryLink}
                  postContent={postData.data}
                />
              </div>
            </Container>
          </section>
          <section>
            <Container padding={"py-10"}>
              <RelatedArticles
                relatedArticles={postData.data.relatedArticles}
              />
            </Container>
          </section>
        </>
      )}
    </>
  );
};

export default SingleArticle;
