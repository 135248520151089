import Container from "./Container";
import React from "react";
import { CgSmartphone } from "react-icons/cg";
import { RiMailSendLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";

const ReadyToHelp = () => {
  const { t } = useTranslation();
  return (
    <Container padding={"xs:py-2 md:py-8"}>
      <div className="text flex lg:justify-between items-center font-semibold">
        <div className="xs:hidden lg:block">
          <p className="flex flex-col">
            <span className="text-base font-bold">
              {t("readyToHelp.firstLine")}
            </span>
            <span className="text-marasi-base font-normal">
              {t("readyToHelp.secondLine")}
            </span>
          </p>
        </div>
        <div className="xs:text-sm md:text-base flex gap-x-4 xs:flex-row lg:flex-wrap flex-auto xs:justify-between lg:justify-end">
          <div>
            <a href="https://wa.me/message/EZF5JFV6QLX2C1" className="flex gap-x-3 items-center">
              <span className="font-bold text-center" style={{ direction: "ltr"}}>+90 505 999 59 95</span>
              <span className="xs:hidden sm:inline">
                <CgSmartphone className="text-2xl" />
              </span>
            </a>
          </div>
          <div className="xs:block lg:hidden text-marasi-secondary-300 font-bold">
            |
          </div>
          <div>
            <a
              href="email:example@gmail.com"
              className="flex gap-x-3 items-center"
            >
              <span className="font-bold text-center">help@elmarasi.com</span>
              <span className="xs:hidden sm:inline">
                <RiMailSendLine className="text-2xl" />
              </span>
            </a>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ReadyToHelp;
