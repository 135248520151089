import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { scrollTo } from "../assets/js/helpers";
import AppTitle from "../components/AppTitle";
import Container from "../components/Container";
import HeroSection from "../components/HeroSection";
import HeroSectionSkeleton from "../components/HeroSectionSkeleton";
import SingleCategoryArticlesSection from "../components/SingleCategoryArticlesSection";
import SingleCategoryArticlesSectionSkeleton from "../components/SingleCategoryArticlesSectionSkeleton";
import { useGetHomePageDataQuery } from "../features/api/api-slice";
import SEO from "../components/SEO";

const Home = () => {
  // const { t, i18n } = useTranslation('common');
  const { t, i18n } = useTranslation();
  const {
    data: homePageData,
    isSuccess,
    isLoading,
  } = useGetHomePageDataQuery(i18n.language);
  const heroSection = isSuccess && homePageData.data.featured;
  const categoriesArticles = isSuccess && homePageData.data.categories;
  const featured = isSuccess && homePageData.data?.featured;

  useEffect(() => {
    scrollTo();
  });
  return (
    <>
      <AppTitle />
      <Container>
        <section className="py-6">
          {isLoading && <HeroSectionSkeleton />}
          {isSuccess && (
            <>
              <SEO
                title={featured.title}
                link={`${document.URL}/${featured.category.slug}/${featured.slug}`}
                description={featured.snippet}
                image={featured.coverImage}
              />
              <HeroSection
                sectionTitle={t("app.appName")}
                articleExcerpt={heroSection}
                categoryLink={heroSection.category.slug}
                postLink={`${heroSection.category.slug}/${heroSection.slug}`}
              />
            </>
          )}
        </section>
        {isLoading &&
          Array(3)
            .fill(true)
            .map((_, index) => {
              return <SingleCategoryArticlesSectionSkeleton key={index} />;
            })}

        {isSuccess &&
          categoriesArticles.map((singleCategoryArticles) => {
            return (
              <section className="py-6" key={singleCategoryArticles.id}>
                <SingleCategoryArticlesSection
                  categoriesArticles={singleCategoryArticles}
                />
              </section>
            );
          })}
      </Container>
    </>
  );
};

export default Home;
