import Container from "./Container";
import React from "react";
import RelatedProducts from "./RelatedProducts";
import ArticleContentHeader from "./ArticleContentHeader";
import AsideRelatedArticles from "./AsideRelatedArticles";
const ArticleContent = ({ postContent, categoryLink }) => {
  return (
    <div className="">
      <div className="article-content-header">
        <ArticleContentHeader
          category={postContent.category.title}
          categoryLink={categoryLink}
          postDate={postContent.postDate}
          title={postContent.title}
        />
      </div>

      <div className="content flex">
        <Container width={"w-full"}>
          <div className="flex gap-x-6">
            <div className="article-body xs:text-sm md:text-base xs:w-full lg:w-10/12 text-justify">
              {postContent.content.map((typeContent, index) => {
                if (typeContent.type === "richText") {
                  return (
                    <div
                      className="pb-9 last:pb-0"
                      dangerouslySetInnerHTML={{ __html: typeContent.content }}
                      key={index}
                    ></div>
                  );
                } else if (typeContent.type === "productSlider") {
                    return (
                        <Container
                            maxWidth={"max-w-full"}
                            width="w-full"
                            padding={"py-12"}
                            key={index}
                        >
                            <RelatedProducts relatedProducts={typeContent.content} />
                        </Container>
                    );
                }
              })}
            </div>
            {postContent.sideArticles && (
              <div className="w-2/12 xs:hidden lg:block">
                <AsideRelatedArticles sideArticles={postContent.sideArticles} />
              </div>
            )}
          </div>
        </Container>
      </div>
    </div>
  );
};

export default ArticleContent;
