import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = ({ breadcrumbHierarchy }) => {
  return (
    <div className="flex xs:text-marasi-sm md:text-sm  xs:font-bold overflow-x-auto md:overflow-x-visible">
      {breadcrumbHierarchy.map((item) => {
        return (
          <React.Fragment key={item.id}>
            {item.clickable ? (
              <Link
                to={`${item.link}`}
                className="first:text-marasi-primary-900 after:content-['/'] last:after:content-none after:text-marasi-secondary-300 after:px-2 py-2"
              >
                <span className="truncate">{item.label}</span>
              </Link>
            ) : (
              <div className="flex flex-nowrap shrink-0 first:text-marasi-primary-900 after:content-['/'] last:after:content-none after:text-marasi-secondary-300 after:px-2 py-2">
                <span className="">{item.label}</span>
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Breadcrumb;
