import React from "react";
import { Link } from "react-router-dom";

const ArticleHeroSection = ({ postExcerpt, categoryLink }) => {
  return (
    <div>
      <div className="category-title pb-4">
        <Link to={categoryLink}>
          <span className="text-base text-marasi-primary-900">
            {postExcerpt.category.title}
          </span>
        </Link>
      </div>
      <div className="title pb-4">
        <h1 className="sm:text-lg md:text-marasi-4xl font-bold">
          {postExcerpt.title}
        </h1>
      </div>
      <div className="hero-image">
        <img
          src={postExcerpt.coverImage}
          alt={postExcerpt.title}
          className="w-full"
        />
      </div>
    </div>
  );
};

export default ArticleHeroSection;
