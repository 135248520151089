import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import ReadyToHelp from "../components/ReadyToHelp";
import { useGetGlobalDataQuery } from "../features/api/api-slice";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Root = () => {
  // const { i18n } = useTranslation('common');
  const { i18n } = useTranslation();
  const { data, isSuccess, isLoading } = useGetGlobalDataQuery(i18n.language);
  const globalData = isSuccess && data.data;
  return (
    <>
      <>
        <header className="border-b px-4">
          {isLoading && <Skeleton className="my-3" height={53} />}
          {isSuccess && <Header headerData={globalData} />}
        </header>

        <main className="px-6">
          <Outlet />
        </main>
        <section className="border-y px-4">
          <ReadyToHelp />
        </section>
        <footer className="px-4">
          {isLoading && <Skeleton count={5} />}
          {isSuccess && <Footer footerData={globalData.footer} />}
        </footer>
      </>
    </>
  );
};

export default Root;
