import React from "react";
import Container from "../components/Container";
import { GrApple } from "react-icons/gr";
import { MdOutlineAndroid } from "react-icons/md";
import paymentOptionsImage from "../assets/images/marasi-payments-options.svg";
import { FaFacebook, FaInstagram, FaSnapchat, FaTwitter } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const Footer = ({ footerData }) => {
  const linksLists = footerData;
  // const { t } = useTranslation('common');
  const { t } = useTranslation();
  const appleLink =
    "https://apps.apple.com/app/marasi-shopping-%D9%85%D8%B1%D8%A7%D8%B3%D9%8A-%D9%84%D9%84%D8%AA%D8%B3%D9%88%D9%82/id1476963784";
  const androidLink =
    "https://play.google.com/store/apps/details?id=com.marasi";
  const termsOfUseLink = "https://elmarasi.com/about/terms-of-use";
  const privacyPolicyLink = "https://elmarasi.com/about/privacy-policy";
  const returnPolicyLink = "https://elmarasi.com/about/return-policy";

  return (
    <>
      <Container>
        <div className="flex flex-wrap gap-x-10 gap-y-5 xs:justify-center lg:justify-start xs:items-center lg:items-start">
          <div className="links-lists justify-between xs:hidden lg:flex flex-auto">
            {linksLists.map((linksList, index) => {
              return <LinksList key={index} linksList={linksList} />;
            })}
          </div>
          <div>
            <DownloadAppLinks
              androidText={t("footer.androidDownload")}
              appleText={t("footer.appleDownload")}
              appleLink={appleLink}
              androidLink={androidLink}
            />
          </div>
          <div className="payments-options">
            <OtherLinks
              contactUsText={t("footer.feedback")}
              newsletterText={t("footer.newsletter")}
              offerText={t("footer.getOffers")}
              suggestionText={t("footer.sendFeedback")}
              newsletterLink="tel:+905443883505"
              suggestionLink="mailto:help@elmarasi.com"
              followUsText={t("footer.followUs")}
            />
          </div>
        </div>
      </Container>
      <Container>
        <div className="text-marasi-secondary-400 text-marasi-base">
          <PolicyAndTerms
            copyrightsText={t("footer.copyRights")}
            privacyPolicyText={t("footer.privacyPolicy")}
            returnPolicyText={t("footer.returnPolicy")}
            termOfUseText={t("footer.termsOfUse")}
            privacyPolicyLink={privacyPolicyLink}
            termsOfUseLink={termsOfUseLink}
            returnPolicyLink={returnPolicyLink}
          />
        </div>
      </Container>
    </>
  );
};

const LinksList = ({ linksList }) => {
  return (
    <div className="text-marasi-base text-marasi-secondary-400">
      <div className="mb-2 font-bold">
        <span>{linksList.title}</span>
      </div>
      <ul>
        {linksList.links.map((link, index) => {
          return (
            <li
              className="py-2 transition-all duration-300 hover:text-black"
              key={index}
            >
              <a href={link.link} target="_blank" rel="noreferrer">
                {link.text}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const DownloadAppLinks = ({
  appleText,
  appleLink,
  androidText,
  androidLink,
}) => {
  return (
    <div className="text-marasi-secondary-400 flex xs:flex-wrap justify-center lg:flex-col gap-4">
      <a href={appleLink}>
        <div className="flex gap-x-4 items-center border px-4 py-2 rounded-lg">
          <div>
            <span>
              <GrApple className="text-3xl" />
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-marasi-sm">{appleText}</span>
            <span className="font-bold">App Store</span>
          </div>
        </div>
      </a>
      <a href={androidLink}>
        <div className="flex gap-x-4 items-center border px-4 py-2 rounded-lg">
          <div>
            <span>
              <MdOutlineAndroid className="text-3xl" />
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-marasi-sm">{androidText}</span>
            <span className="font-bold">Google Play</span>
          </div>
        </div>
      </a>
    </div>
  );
};

const OtherLinks = ({
  offerText,
  newsletterLink,
  newsletterText,
  contactUsText,
  suggestionLink,
  suggestionText,
  followUsText,
}) => {
  return (
    <div className="flex flex-col gap-y-4 text-marasi-base text-marasi-secondary-400">
      <div className="xs:hidden lg:block">
        <p>{offerText}</p>
        <a href={newsletterLink}>{newsletterText}</a>
      </div>
      <div className="xs:hidden lg:block">
        <p>{contactUsText}</p>
        <a href={suggestionLink}>{suggestionText}</a>
      </div>
      <div className="flex xs:justify-center lg:justify-start">
        <FollowUs followUsText={followUsText} />
      </div>
      <div className="payment-options h-4">
        <PaymentOptions followUsText={followUsText} />
      </div>
    </div>
  );
};

const FollowUs = ({ followUsText }) => {
  return (
    <div className="flex xs:justify-center lg:justify-start flex-col">
      <div className="mb-2 flex xs:justify-center lg:justify-start">
        <span>{followUsText}</span>
      </div>
      <div>
        <ul className="flex gap-x-2 text-lg">
          <li className="transition-all duration-300 hover:text-black">
            <a href="https://www.facebook.com/marasicom/">
              <FaFacebook />
            </a>
          </li>
          <li className="transition-all duration-300 hover:text-black">
            <a href="https://www.instagram.com/marasicom/">
              <FaInstagram />
            </a>
          </li>
          <li className="transition-all duration-300 hover:text-black">
            <a href="https://twitter.com/marasicom">
              <FaTwitter />
            </a>
          </li>
          <li className="transition-all duration-300 hover:text-black">
            <a href="https://www.snapchat.com/add/marasicom">
              <FaSnapchat />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

const PaymentOptions = () => {
  return <img src={paymentOptionsImage} alt="" className="w-full h-auto" />;
};

const PolicyAndTerms = ({
  copyrightsText,
  termOfUseText,
  privacyPolicyText,
  returnPolicyText,
  termsOfUseLink,
  privacyPolicyLink,
  returnPolicyLink,
}) => {
  return (
    <div className="flex xs:justify-center lg:justify-between items-center border-t pt-4">
      <div className="copy-rights">
        <p>
          {copyrightsText} &copy; {new Date().getFullYear()}
        </p>
      </div>
      <div className="xs:hidden lg:flex gap-x-4">
        <FooterLink link={termsOfUseLink}>{termOfUseText}</FooterLink>
        <FooterLink link={privacyPolicyLink}>{privacyPolicyText}</FooterLink>
        <FooterLink link={returnPolicyLink}>{returnPolicyText}</FooterLink>
      </div>
    </div>
  );
};

const FooterLink = ({ link, children }) => {
  return (
    <a
      href={link}
      className="text-marasi-secondary-400 transition-all duration-300 hover:text-black cursor-pointer"
    >
      {children}
    </a>
  );
};

export default Footer;
