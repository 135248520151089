const Container = ({
  Component = "div",
  backgroundColor,
  children,
  maxWidth,
  width,
  margin,
  display,
  padding,
}) => {
  const classes = Object.values({
    initialClasses: "",
    display: display ? display : "block",
    maxWidth: maxWidth
      ? maxWidth
      : "xs:max-w-full sm:max-w-[540px] md:max-w-[720px] lg:max-w-[1140px]",
    width: width ? width : "w-[95%]",
    margin: margin ? margin : "m-auto",
    backgroundColor: backgroundColor ? backgroundColor : "bg-inherit",
    padding: padding ? padding : "py-8",
  }).join(" ");

  return <Component className={classes}>{children}</Component>;
};

export default Container;
