import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import i18next from "../../assets/js/i18next";

const baseUrl = "https://elmarasi.com/api/blog/";
const generalTagTypes = {
  post: "Post",
  Category: "Category",
};
const API_KEY = process.env.REACT_APP_API_KEY;

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, api) => {
    headers.set("content-type", "application/x-www-form-urlencoded");
    headers.set("Accept", "application/json");
    headers.set("X-APIKEY", API_KEY);
    if (!api.searchPosts) {
      headers.set("X-LANG", i18next.language);
    }

    return headers;
  },
});

const categoryPostsByCategoryQuery = (slug, lng) => ({
  url: `/categories-posts?slug=${slug}`,
});
const postBySlugQuery = (slug) => ({
  url: `/post?slug=${slug}`,
});
const generalQuery = () => ({
  url: "/general",
});
const homeQuery = () => ({
  url: "/home",
});
const searchQuery = (query) => `/search?q=${query}`;

const apiSlice = createApi({
  reducerPath: "api",
  baseQuery,
  tagTypes: Object.values(generalTagTypes),
  endpoints: (builder) => ({
    getPostBySlug: builder.query({
      query: postBySlugQuery,
      providesTags: (result) => [
        generalTagTypes.post,
        { type: generalTagTypes.post, slug: result.data?.slug },
      ],
    }),
    getGlobalData: builder.query({
      query: generalQuery,
      providesTags: Object.values(generalTagTypes),
    }),
    getHomePageData: builder.query({
      query: homeQuery,
      providesTags: Object.values(generalTagTypes),
    }),
    getCategoryPostsByCategorySlug: builder.query({
      query: categoryPostsByCategoryQuery,
      providesTags: (result) => [
        generalTagTypes.Category,
        { type: generalTagTypes.Category, slug: result.data?.slug },
      ],
    }),
    searchPosts: builder.query({
      query: searchQuery,
      providesTags: (result, error, arg) => [
        generalTagTypes.post,
        { type: generalTagTypes.post, query: arg },
      ],
    }),
  }),
});

export { apiSlice };
export const {
  useGetGlobalDataQuery,
  useGetHomePageDataQuery,
  useGetCategoryPostsByCategorySlugQuery,
  useGetPostBySlugQuery,
  useSearchPostsQuery,
} = apiSlice;
