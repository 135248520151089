import React, { useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createGlobalStyle } from "styled-components";
import { IoIosArrowDown } from "react-icons/io";
import { toggleDropDown } from "./header-slice";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const DropDownNavItem = ({ navigationList, handleLinkClick }) => {
  const params = useParams();
  const { t, i18n } = useTranslation();
  const state = useSelector((state) => state);
  const headerState = state.header;
  const open = headerState.dropDownOpen;
  const dispatch = useDispatch();
  const dropDownRef = useRef(null);
  useEffect(() => {
    document.addEventListener("click", (event) => {
      if (event.currentTarget !== dropDownRef.current) {
        dispatch(toggleDropDown(false));
      }
    });
  });

  const handleClick = (event) => {
    event.stopPropagation();
    dispatch(toggleDropDown(!open));
  };
  return (
    <div className="">
      <div className="drop-down-button p-4">
        <button onClick={(event) => handleClick(event)}>
          <div
            className={`flex justify-between items-center gap-x-4 shrink-0 ${
              open ? "text-marasi-primary-900" : ""
            } transition-all duration-200`}
          >
            <span className="">{t("header.categories")}</span>
            <span>
              <IoIosArrowDown />
            </span>
          </div>
        </button>
      </div>
      <DropDownTransitionStyle />
      <CSSTransition
        nodeRef={dropDownRef}
        in={open}
        timeout={300}
        classNames="drop-down"
        unmountOnExit
      >
        <div className={`drop-down-list flex flex-col relative px-4`}>
          <div
            ref={dropDownRef}
            className="xs:static lg:absolute border flex flex-col min-w-[250px] bg-white xs:shadow-0 lg:shadow-lg z-10"
          >
            {navigationList.map((navItem) => {
              return (
                <Link
                  to={navItem.slug}
                  className={`p-4 border-b last:border-b-0 hover:bg-gray-100 transition-all duration-200 ${
                    params.categorySlug === navItem.slug
                      ? "text-marasi-primary-900"
                      : ""
                  } `}
                  key={navItem.id}
                  onClick={handleLinkClick}
                >
                  {navItem.title}
                </Link>
              );
            })}
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

const DropDownTransitionStyle = createGlobalStyle`
  .drop-down-enter {
    opacity: 0;
    transform: TranslateY(-10px);
  }
  .drop-down-enter-active {
    opacity: 1;
    transform: translateY(0px);
    transition: opacity 300ms, transform 300ms;
  }
  .drop-down-exit {
    opacity: 1;
  }
  .drop-down-exit-active {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 300ms, transform 300ms;
  }
  `;

export default DropDownNavItem;
