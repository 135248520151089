import { createSlice } from "@reduxjs/toolkit";
import { screens } from "tailwindcss/defaultTheme";

const lgScreenBreakPoint = Number(screens.lg.slice(0, -2));
const initialState = {
  dropDownOpen: false,
  searchNavItemOpen: false,
  currentScreenWidth: window.innerWidth,
  isMobileScreen: window.innerWidth < lgScreenBreakPoint,
  isMobileHeaderOpen: false,
  isSearchResultsOpen: false,
  instantSearchResults: [],
};

const headerSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    toggleDropDown: (state, { payload }) => {
      state.dropDownOpen = payload;
    },
    toggleSearchNavItem: (state, { payload }) => {
      state.searchNavItemOpen = payload;
    },
    setCurrentScreenWidth: (state, { payload }) => {
      state.currentScreenWidth = payload;
    },
    setIsMobileScreen: (state, { payload }) => {
      state.isMobileScreen = payload < lgScreenBreakPoint;
    },
    setIsMobileHeaderOpen: (state, { payload }) => {
      state.isMobileHeaderOpen = payload;
    },
    setIsSearchResultsOpen: (state, { payload }) => {
      state.isSearchResultsOpen = payload;
    },
    updateInstantSearchResults: (state, { payload }) => {
      state.instantSearchResults = payload;
    },
  },
});

export const {
  toggleDropDown,
  toggleSearchNavItem,
  setCurrentScreenWidth,
  setIsMobileHeaderOpen,
  setIsMobileScreen,
  setIsSearchResultsOpen,
  updateInstantSearchResults,
} = headerSlice.actions;
export default headerSlice.reducer;
