import React from "react";
import Skeleton from "react-loading-skeleton";
import ArticleCardSkeleton from "./ArticleCardSkeleton";

const SingleCategoryArticlesSectionSkeleton = () => {
  return (
    <div className="py-6">
      <div className="articles-header text-2xl flex justify-between pb-4">
        <div className="category-title w-2/12">
          <Skeleton />
        </div>
        <div className="category-see-all xs:text-base md:text-xl w-1/12">
          <Skeleton />
        </div>
      </div>
      <div className="category-articles flex gap-5 flex-wrap">
        {Array(3)
          .fill(true)
          .map((_, index) => {
            return <ArticleCardSkeleton key={index} />;
          })}
      </div>
    </div>
  );
};

export default SingleCategoryArticlesSectionSkeleton;
