import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "../assets/css/custom-swiper.css";
import ArticleCard from "./ArticleCard";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SingleCategoryArticlesSection = ({ categoriesArticles }) => {
  // const { t } = useTranslation('common');
  const { t } = useTranslation();
  const handleAfterInitiation = (swiper) => {
    const paginationEl = swiper.pagination.el;
    const appendedEl = document.createElement("div");
    appendedEl.classList.add("h-5");
    swiper.el.appendChild(appendedEl);
    paginationEl.style.bottom = 0;
  };
  return (
    <div>
      <div className="articles-header text-2xl flex justify-between pb-4 items-center">
        <div className="category-title xs:text-lg lg:text-2xl">
          <h3 className="font-bold">{categoriesArticles.title}</h3>
        </div>
        <div className="category-see-all xs:text-base md:text-lg">
          <Link
            to={`${categoriesArticles.slug}`}
            className="text-marasi-primary-900"
          >
            {t("categories.showMore")}
          </Link>
        </div>
      </div>
      <div className="category-articles flex">
        <Swiper
          spaceBetween={20}
          modules={[Pagination, Autoplay]}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            920: {
              slidesPerView: 3,
            },
            768: {
              slidesPerView: 2,
            },
            540: {
              slidesPerView: 1.5,
            },
            320: {
              slidesPerView: 1,
            },
          }}
          onAfterInit={(swiper) => handleAfterInitiation(swiper)}
        >
          {categoriesArticles.posts.map((article) => (
            <SwiperSlide key={article.id}>
              <ArticleCard
                article={article}
                categoryLink={article.category.slug}
                postLink={`${article.category.slug}/${article.slug}`}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default SingleCategoryArticlesSection;
