import React, { useEffect } from "react";
import ArticleCard from "../components/ArticleCard";
import Breadcrumb from "../components/Breadcrumb";
import Container from "../components/Container";
import HeroSection from "../components/HeroSection";
import AppTitle from "../components/AppTitle";
import { useGetCategoryPostsByCategorySlugQuery } from "../features/api/api-slice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import BreadcrumbSkeleton from "../components/BreadcrumbSkeleton";
import HeroSectionSkeleton from "../components/HeroSectionSkeleton";
import ArticleCardSkeleton from "../components/ArticleCardSkeleton";
import { scrollTo } from "../assets/js/helpers";
import SEO from "../components/SEO";

const Category = () => {
  const params = useParams();
  const { data, isSuccess, isLoading } = useGetCategoryPostsByCategorySlugQuery(
    params.categorySlug
  );
  // const { t } = useTranslation('common');
  const { t } = useTranslation();

  const categoryPosts = isSuccess && data.data;
  const firstPost = categoryPosts && categoryPosts.posts[0];
  const breadcrumbHierarchy = [
    {
      id: 1,
      label: t("app.appName"),
      link: "/",
      clickable: true,
    },
    {
      id: 2,
      label: isSuccess && categoryPosts.title,
      clickable: false,
    },
  ];

  useEffect(() => {
    scrollTo();
  });

  return (
    <>
      <AppTitle>{isSuccess && categoryPosts.title}</AppTitle>
      <main className="px-3">
        <Container>
          {isLoading && <BreadcrumbSkeleton />}
          {isSuccess && (
            <Breadcrumb breadcrumbHierarchy={breadcrumbHierarchy} />
          )}
        </Container>

        <section className="pb-9">
          <Container padding="p-0">
            {isLoading && <HeroSectionSkeleton />}
            {isSuccess && (
              <>
                <SEO
                  link={document.URL}
                  description={firstPost.snippet}
                  title={firstPost.title}
                  image={firstPost.coverImage}
                />
                <HeroSection
                  sectionTitle={categoryPosts.title}
                  articleExcerpt={categoryPosts.posts[0]}
                  postLink={categoryPosts.posts[0].slug}
                />
              </>
            )}
          </Container>
        </section>
        <section>
          <Container>
            <div className="flex flex-wrap gap-y-10 -mx-3">
              {isLoading &&
                Array(5)
                  .fill(true)
                  .map((_, index) => {
                    return (
                      <div
                        className="xs:w-full md:w-1/2 lg:w-1/3 px-3"
                        key={index}
                      >
                        <ArticleCardSkeleton />
                      </div>
                    );
                  })}
              {isSuccess &&
                categoryPosts.posts.slice(1).map((article, index) => {
                  return (
                    <div
                      className="xs:w-full md:w-1/2 lg:w-1/3 px-3"
                      key={index}
                    >
                      <ArticleCard article={article} postLink={article.slug} />
                    </div>
                  );
                })}
            </div>
          </Container>
        </section>
      </main>
    </>
  );
};

export default Category;
