import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "../features/api/api-slice";
import headerSlice from "../features/header/header-slice";
import appSlice from "../features/app/app-slice";

const store = configureStore({
  reducer: {
    header: headerSlice,
    [apiSlice.reducerPath]: apiSlice.reducer,
    app: appSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});

export default store;
