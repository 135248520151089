import React, { useState } from "react";
import { FaFacebookSquare, FaTwitterSquare } from "react-icons/fa";
import { RiWhatsappFill } from "react-icons/ri";
import { HiOutlineLink } from "react-icons/hi";
import { copyContentToClipboard } from "../assets/js/helpers";
import { useTranslation } from "react-i18next";

const ArticleContentHeader = ({ postDate, title }) => {
  const currentLink = window.location.href;
  return (
    <div className="content-header flex pb-3 text-xl items-center justify-between gap-x-5">
      <div className="share text-marasi-secondary-300 flex gap-x-2">
        <ShareArticle link={currentLink} title={title} />
      </div>
      <div className="date text-marasi-secondary-300 text-sm">
        <span>{postDate}</span>
      </div>
    </div>
  );
};

const ShareArticle = ({ link, title }) => {
  const facebook = `https://www.facebook.com/sharer.php?u=${link}`;
  const whatsapp = `https://wa.me/?text=${title}`;
  const twitter = `https://twitter.com/share?url=${link}&text=${title}&hashtags=elmarasi_blog`;

  const [isCopied, setIsCopied] = useState(false);
  const { t } = useTranslation();
  const handleCopyClick = () => {
    setIsCopied(copyContentToClipboard(window.location.href));
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };
  return (
    <>
      <div className="fb transition-all duration-300 hover:text-black">
        <a href={whatsapp} target="_blank" rel="noreferrer">
          <RiWhatsappFill />
        </a>
      </div>
      <div className="twitter transition-all duration-300 hover:text-black">
        <a href={twitter} target="_blank" rel="noreferrer">
          <FaTwitterSquare />
        </a>
      </div>
      <div className="instagram transition-all duration-300 hover:text-black">
        <a href={facebook} target="_blank" rel="noreferrer">
          <FaFacebookSquare />
        </a>
      </div>
      <div className="copy-link transition-all duration-300 hover:text-black">
        <div className="cursor-pointer flex gap-x-1 relative" title="Copy Link">
          <span onClick={handleCopyClick}>
            <HiOutlineLink />
          </span>
          {isCopied ? (
            <span className="text-[12px] inline-block px-2 absolute left-1/2 -translate-x-1/2 top-1/2 translate-y-1/2 text-center border w-16 rounded-md bg-white">
              {t("article.copied")}
            </span>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ArticleContentHeader;
