import React from "react";
import Skeleton from "react-loading-skeleton";

const ArticleCardSkeleton = () => {
  return (
    <div className="xs:w-full md:w-auto md:flex-auto">
      <div className="image pb-3">
        <Skeleton height={200} />
      </div>
      <div className="content">
        <div className="content-header flex justify-between text-sm pb-3">
          <div className="category-title text-marasi-primary-900 w-1/12">
            <h5>
              <Skeleton />
            </h5>
          </div>
          <div className="date text-marasi-secondary-300 w-1/12">
            <Skeleton />
          </div>
        </div>
        <div className="article-title text-base pb-3">
          <h4>
            <Skeleton />
          </h4>
        </div>
        <div className="excerpt">
          <Skeleton />
        </div>
      </div>
    </div>
  );
};

export default ArticleCardSkeleton;
