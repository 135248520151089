import React from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "../assets/css/custom-swiper.css";

import { useTranslation } from "react-i18next";
import ShopNow from "./ShopNow";

const RelatedProducts = ({ relatedProducts }) => {
  const suffix = Math.floor(Math.random() * 10000);
  const nextClass = `next-${suffix}`;
  const prevClass = `prev-${suffix}`;
  return (
    <div className="relative">
      <Swiper
        modules={[Navigation, Autoplay]}
        className="xs:w-9/12 md:w-5/6"
        dir="rtl"
        navigation={{
          prevEl: `.${prevClass}`,
          nextEl: `.${nextClass}`,
        }}
        spaceBetween={20}
        breakpoints={{
          920: {
            slidesPerView: 3,
          },
          540: {
            slidesPerView: 2,
          },
          320: {
            slidesPerView: 1,
          },
        }}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
      >
        {relatedProducts.map((product) => {
          return (
            <SwiperSlide key={product.id}>
              <Product product={product} />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div
        className={`${nextClass} absolute top-1/2 -translate-y-1/2 left-0 cursor-pointer`}
      >
        <span className=" flex justify-center items-center xs:h-7 xs:w-7 sm:h-9 sm:w-9 rounded-full bg-[#f6f6f6]">
          <IoIosArrowBack />
        </span>
      </div>
      <div
        className={`${prevClass} absolute top-1/2 -translate-y-1/2 right-0 cursor-pointer`}
      >
        <span className="flex justify-center items-center xs:h-7 xs:w-7 sm:h-9 sm:w-9 rounded-full bg-[#f6f6f6]">
          <IoIosArrowForward />
        </span>
      </div>
    </div>
  );
};

const Product = ({ product }) => {
  // const { t } = useTranslation('common');
  const { t } = useTranslation();
  return (
    <div className="text-sm flex justify-center text-center">
      <a
        href={product.link}
        target={"_blank"}
        rel="noreferrer"
        className="flex flex-col justify-center items-center"
      >
        <div className="image h-44 flex justify-center pb-2 overflow-hidden">
          <img
            src={product.image}
            alt=""
            className="w-full h-52 object-center object-contain"
          />
        </div>
        <div className="title px-2 py-3 w-2/3">
          <h5>{product.title}</h5>
        </div>
        <div className="brand text-marasi-secondary-200">
          <span>{product.brand}</span>
        </div>
        <div className="shop text-marasi-base font-semibold py-3 w-1/2 flex justify-center items-center">
          <ShopNow>{t("header.shopNow")}</ShopNow>
        </div>
      </a>
    </div>
  );
};

export default RelatedProducts;
