import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Category from "../pages/Category";
import Root from "../routes/Root";
import Home from "../pages/Home";
import SingleArticle from "../pages/SingleArticle";
import { createGlobalStyle } from "styled-components";
import { I18nextProvider, useTranslation } from "react-i18next";
import ErrorPage from "../pages/ErrorPage";
import SearchResults from "../pages/SearchResults";
import { HelmetProvider } from "react-helmet-async";
import { useSelector } from "react-redux";

function App() {
  const { t, i18n } = useTranslation();
  const homeRoute = useSelector((state) => state.app.homeRoute);
  let routesList = [];
  // for(const language of languages){
  routesList.push({ path: `/`, element: <Home /> });
  routesList.push({ path: `:categorySlug/`, element: <Category /> });
  routesList.push({ path: `search/:query`, element: <SearchResults /> });
  routesList.push({
    path: `:categorySlug/:articleSlug`,
    element: <SingleArticle />,
  });
  // }

  const router = createBrowserRouter(
    [
      {
        path: "/",
        element: <Root />,
        errorElement: <ErrorPage />,
        children: routesList,
      },
    ], {
      basename: i18n.language === "en" ? `/en/${homeRoute}` : `/${homeRoute}`,
    }
  );

  return (
    <I18nextProvider i18n={i18n}>
      <AppGlobalStyle
        direction={t("app.pageDirection")}
        fontFamily={t("app.fontFamily")}
      />
      <HelmetProvider>
        <RouterProvider router={router} />
      </HelmetProvider>
    </I18nextProvider>
  );
}

const AppGlobalStyle = createGlobalStyle`
  body {
    direction: ${(props) => props.direction};
    font-family: ${(props) => props.fontFamily};
  }
`;

export default App;
