import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";

const AppTitle = ({ children }) => {
  const { t } = useTranslation();

  return (
    <HelmetProvider>
      <Helmet>
        <title>{`${t("app.appName")} ${
          children ? "| " + children : ""
        }`}</title>
      </Helmet>
    </HelmetProvider>
  );
};

export default AppTitle;
