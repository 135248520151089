import React from "react";
import CustomCSSTransition from "../../components/CustomCSSTransition";
import DropDownNavItem from "./DropDownNavItem";
import SearchNavItem from "./SearchNavItem";
import ShopNow from "../../components/ShopNow";
import { useSelector } from "react-redux";
import ChangeLanguageNavItem from "./ChangeLanguageNavItem";
import { useTranslation } from "react-i18next";

const DesktopHeader = ({ categories }) => {
  // const { t } = useTranslation("common");
  const { t } = useTranslation();
  const headerState = useSelector((state) => state.header);
  const searchNavItemOpen = headerState.searchNavItemOpen;
  return (
    <div className="nav-items flex items-center gap-x-5 text-base">
      <CustomCSSTransition inValue={!searchNavItemOpen} timeout={200}>
        <DropDownNavItem navigationList={categories} />
      </CustomCSSTransition>
      <CustomCSSTransition inValue={!searchNavItemOpen} timeout={200}>
        <ChangeLanguageNavItem />
      </CustomCSSTransition>
      <div className="nav-item py-3">
        <SearchNavItem />
      </div>
      <div className="shop-now shrink-0">
        <a href="https://elmarasi.com/" target={"_blank"} rel="noreferrer">
          <ShopNow>{t("header.shopNow")}</ShopNow>
        </a>
      </div>
    </div>
  );
};

export default DesktopHeader;
