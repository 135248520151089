import React from "react";
import { Link } from "react-router-dom";
const HeroSection = ({
  sectionTitle,
  articleExcerpt,
  categoryLink,
  postLink,
}) => {
  return (
    <div>
      <div className="pb-9">
        <h1 className="section-title text-marasi-4xl font-bold">
          {sectionTitle}
        </h1>
      </div>
      <div className="section-content flex xs:flex-col lg:flex-row gap-6">
        <div className="image xs:w-full md:flex-1">
          <Link to={postLink} className="h-full w-full">
            <img
              src={articleExcerpt.coverImage}
              alt={articleExcerpt.title}
              className="w-full h-full object-cover"
            />
          </Link>
        </div>
        <div className="text flex flex-col relative  xs:w-full lg:flex-1 text-sm gap-2">
          <div className="marasi-new w-1/2">
            <Link
              to={categoryLink}
              className="text-base text-marasi-primary-900"
            >
              <h5>{articleExcerpt.category.title}</h5>
            </Link>
          </div>
          <div className="article-title w-full xs:order-3 md:order-none">
            <Link to={postLink}>
              <h4 className="xs:text-lg md:text-2xl">{articleExcerpt.title}</h4>
            </Link>
          </div>
          <div className="article-body xs:order-last md:order-none w-full text-justify text-marasi-secondary-200">
            {/* <p className="p-0">{articleExcerpt.snippet}</p> */}
            <div
              className="p-0"
              dangerouslySetInnerHTML={{ __html: articleExcerpt.snippet }}
            ></div>
          </div>
          <div className="article-date w-full lg:w-1/2 text-sm xs:absolute md:static top-0 left-0 flex xs:justify-end md:justify-start">
            <span className="text-marasi-secondary-500">
              {articleExcerpt.postDate}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
