import React from "react";
import Container from "../components/Container";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import spaceImage from "../assets/images/space.svg";
import astronautImage from "../assets/images/astronaut.svg";
import earthImage from "../assets/images/small-earth.svg";
import spaceRockImage from "../assets/images/space-rock.svg";
import jupitarImage from "../assets/images/jupitar.svg";
import styled, { keyframes } from "styled-components";

const ErrorPage = () => {
  // const { t } = useTranslation('common');
  const { t } = useTranslation();
  return (
    <Container padding={"p-0"}>
      <div className="flex flex-col justify-center items-center h-screen text-center gap-y-10">
        <div className="oops-content flex flex-col gap-y-4">
          <span className="text-6xl font-bold">{t("error.oops")}</span>
          <span className="text-2xl">{t("error.lost")}</span>
          <span className="text-3xl font-bold font-['Inter']">
            {t("error.errorNumber")}
          </span>
        </div>
        <div className="image">
          <ErrorImagesGroup />
          {/* <img src={notFound} alt="error image" /> */}
        </div>
        <div>
          <GoHomeButton>{t("error.goHome")}</GoHomeButton>
        </div>
      </div>
    </Container>
  );
};

const GoHomeButton = ({ children }) => {
  // const { i18n } = useTranslation('common');
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  return (
    <Link
      to={"/"}
      className={`uppercase inline-block xs:text-base ${
        currentLanguage === "ar" ? "lg:text-base" : "lg:text-lg"
      } py-2 px-5 border border-black font-light`}
    >
      {children}
    </Link>
  );
};

const ErrorImagesGroup = () => {
  return (
    <div className="relative">
      <div className="space">
        <img src={spaceImage} alt="space" />
      </div>
      <AnimatedElement
        className="astronaut absolute top-0 left-0 w-1/2"
        keyframes={rotateAndMove}
      >
        <img src={astronautImage} alt="astronaut" />
      </AnimatedElement>
      <div className="earth absolute bottom-1/4 left-1/4 animate-[spin_50s_linear_infinite]">
        <img src={earthImage} alt="earth" />
      </div>
      <div className="space-rock absolute bottom-0 right-0 w-1/3">
        <img src={spaceRockImage} alt="space rock" />
      </div>
      <AnimatedElement
        className="Jupiter absolute top-[20%] right-0 w-1/4"
        keyframes={moveY}
      >
        <img src={jupitarImage} alt="" />
      </AnimatedElement>
    </div>
  );
};

const moveY = keyframes`
 50% {
  transform: translateY(25px);
 }
 100% {
  transform: translateY(0);
 }
`;

const rotateAndMove = keyframes`
0 {
  transform: translate(0px, 0px);
}
25% {
  transform: rotate(-15deg) translate(20px, 20px);
}
50% {
  transform: translate(-40px, 30px);
}

100% {
  transform: translate(0px, 0px);
}
`;

const AnimatedElement = styled.div`
  animation: ${({ keyframes }) => keyframes} ${({ time = 5 }) => time}s
    ${({ timingFunction = "ease-in-out" }) => timingFunction} infinite;
`;

export default ErrorPage;
