import React, { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { createGlobalStyle } from "styled-components";

const CustomCSSTransition = ({
  children,
  inValue,
  classNames = "custom-class-names",
  timeout = 300,
}) => {
  const customRef = useRef(null);
  return (
    <>
      <CustomCSSTransitionStyle classNames={classNames} timeout={timeout} />
      <CSSTransition
        in={inValue}
        classNames={classNames}
        timeout={timeout}
        unmountOnExit
        nodeRef={customRef}
      >
        <div ref={customRef}>{children}</div>
      </CSSTransition>
    </>
  );
};

const CustomCSSTransitionStyle = createGlobalStyle`
  .${(props) => props.classNames}-enter {
    opacity: 0;
    // transform: TranslateY(-10px);
  }
  .${(props) => props.classNames}-enter-active {
    opacity: 1;
    // transform: translateY(0px);
    transition: opacity ${(props) => props.timeout}ms;
  }
  .${(props) => props.classNames}-exit {
    opacity: 1;
  }
  .${(props) => props.classNames}-exit-active {
    opacity: 0;
    // transform: translateY(-10px);
    transition: opacity ${(props) => props.timeout}ms;
  }
  `;

export default CustomCSSTransition;
