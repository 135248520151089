import React from "react";
import { Link } from "react-router-dom";

const ArticleCard = ({ article, categoryLink, postLink }) => {
  return (
    <div className="">
      <div className="image pb-3">
        <Link to={postLink}>
          <img
            src={article.coverImage}
            alt={article.title}
            className="w-full h-52 object-cover"
          />
        </Link>
      </div>
      <div className="content">
        <div className="content-header flex justify-between text-sm py-3">
          <div className="category-title text-marasi-primary-900">
            <h5>
              <Link to={categoryLink}>{article.category.title}</Link>
            </h5>
          </div>
          <div className="date text-marasi-secondary-300">
            <span>{article.postDate}</span>
          </div>
        </div>
        <div className="article-title text-base pb-3">
          <h4>
            <Link to={postLink}>{article.title}</Link>
          </h4>
        </div>
        <div className="excerpt">
          <p className="text-sm text-marasi-secondary-300 text-justify">
            {article.snippet && article.snippet.slice(0, 165)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ArticleCard;
