import React, { useEffect } from "react";
import DropDownNavItem from "../features/header/DropDownNavItem";
import ShopNow from "../components/ShopNow";
import SearchNavItem from "../features/header/SearchNavItem";
import Container from "../components/Container";
import { useSelector, useDispatch } from "react-redux";
import { setIsMobileHeaderOpen } from "../features/header/header-slice";
import { toggleScrolling } from "../assets/js/helpers";
import { useTranslation } from "react-i18next";
import ChangeLanguageNavItem from "../features/header/ChangeLanguageNavItem";

const MobileHeader = ({ categories, handleLinkClick }) => {
  // const { t } = useTranslation('common');
  const { t } = useTranslation();
  const isMobileHeaderOpen = useSelector(
    (state) => state.header.isMobileHeaderOpen
  );

  useEffect(() => {
    toggleScrolling(isMobileHeaderOpen);
  }, [isMobileHeaderOpen]);

  useEffect(() => {
    document.addEventListener("click", () => {});
  });

  return (
    <div className="">
      <div className="">
        <MenubarButton />
      </div>
      <nav
        className={`flex flex-col left-0 z-10 top-[74px] absolute bg-white w-full overflow-hidden transition-all duration-300 ${
          isMobileHeaderOpen
            ? "opacity-100 h-[calc(100vh_-_74px)]"
            : "opacity-0 -translate-y-9 h-0"
        }`}
      >
        <Container margin={"mx-auto"}>
          <div>
            <DropDownNavItem
              navigationList={categories}
              handleLinkClick={handleLinkClick}
            />
          </div>
          <div className="p-4" onClick={handleLinkClick}>
            <ChangeLanguageNavItem />
          </div>
          <div className="p-4">
            <SearchNavItem handleLinkClick={handleLinkClick} />
          </div>
          <div className="shop-now shrink-0 p-4">
            <a href="https://elmarasi.com" target={"_blank"} rel="noreferrer">
              <ShopNow>{t("header.shopNow")}</ShopNow>
            </a>
          </div>
        </Container>
      </nav>
    </div>
  );
};

const MenubarButton = () => {
  const isMobileHeaderOpen = useSelector(
    (state) => state.header.isMobileHeaderOpen
  );
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setIsMobileHeaderOpen(!isMobileHeaderOpen));
  };
  return (
    <button className="menu-button" onClick={handleClick}>
      <div className="menu-bars w-9 h-9 flex flex-col justify-center items-end">
        <span
          className={`border bg-black rounded-full border-black w-2/3 my-1 relative transition-all duration-300 ${
            isMobileHeaderOpen ? "rotate-45 translate-y-[6px]" : "rotate-0"
          }`}
        ></span>
        <span
          className={`border bg-black rounded-full border-black w-2/3 transition-all duration-300 ${
            isMobileHeaderOpen ? "opacity-0" : "opacity-100"
          }`}
        ></span>
        <span
          className={`border bg-black rounded-full border-black w-2/3 my-1 relative transition-all duration-300 ${
            isMobileHeaderOpen ? "-rotate-45 -translate-y-[6px]" : "rotate-0"
          }`}
        ></span>
      </div>
    </button>
  );
};

export default MobileHeader;
