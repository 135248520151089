import React from "react";
import Skeleton from "react-loading-skeleton";

const HeroSectionSkeleton = () => {
  return (
    <div>
      <div className="pb-3">
        <Skeleton width={"33%"} height={30} />
      </div>
      <div className="section-content flex xs:flex-col md:flex-row gap-6">
        <div className="image xs:w-full md:w-2/5">
          <Skeleton height={300} />
        </div>
        <div className="text flex flex-col relative  xs:w-full md:w-3/5 text-sm">
          <div className="marasi-new w-1/2 pb-4">
            <Skeleton height={30} />
          </div>
          <div className="article-title pb-4 w-full xs:order-3 md:order-none">
            <Skeleton height={30} />
          </div>
          <div className="article-body pb-4 xs:order-last md:order-none w-full">
            <Skeleton height={200} />
          </div>
          <div className="article-date w-1/2 xs:absolute md:static top-0 left-0 flex xs:justify-end md:justify-start">
            <Skeleton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSectionSkeleton;
