import React from "react";
import { Link } from "react-router-dom";

const AsideRelatedArticles = ({ sideArticles }) => {
  return (
    <div className="flex flex-col gap-y-8">
      {sideArticles.map((article) => {
        return (
          <SingleRelateArticle
            key={article.id}
            image={article.coverImage}
            title={article.title}
            link={`/${article.category.slug}/${article.slug}`}
          />
        );
      })}
    </div>
  );
};

const SingleRelateArticle = ({ image, title, link }) => {
  return (
    <Link
      to={link}
      className="flex flex-col gap-y-2 text-sm text-marasi-secondary-400"
    >
      <div className="image">
        <img src={image} alt="" />
      </div>
      <div className="title transition-all duration-300 hover:text-black">
        <h5>{title}</h5>
      </div>
    </Link>
  );
};

export default AsideRelatedArticles;
