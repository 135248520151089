import React, { useEffect } from "react";
import Container from "../components/Container";
import logoAr from "../assets/images/marasi-logo.svg";
import logoEn from "../assets/images/marasi-logo-en.svg";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import MobileHeader from "./MobileHeader";
import {
  setIsMobileScreen,
  setIsMobileHeaderOpen,
} from "../features/header/header-slice";
import DesktopHeader from "../features/header/DesktopHeader";
import { useTranslation } from "react-i18next";

const languages = {
  en: "en",
  ar: "ar",
};

const Header = ({ headerData }) => {
  const categories = headerData.categories;
  // const { i18n } = useTranslation('common');
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const isMobileScreen = useSelector((state) => state.header.isMobileScreen);
  const dispatch = useDispatch();
  useEffect(() => {
    window.addEventListener("resize", () => {
      dispatch(setIsMobileScreen(window.innerWidth));
    });
  }, [isMobileScreen, dispatch]);

  const handleLinkClick = () => {
    if (isMobileScreen) {
      dispatch(setIsMobileHeaderOpen(false));
    }
  };

  return (
    <Container padding={"p-0"}>
      <nav className="flex items-center justify-between">
        <div className="logo h-16 py-3">
          <Link to={`/`}>
            <img
              src={currentLanguage === languages.ar ? logoAr : logoEn}
              alt=""
              className="h-full"
            />
          </Link>
        </div>
        {isMobileScreen ? (
          <div className="mobile-header">
            <MobileHeader
              categories={categories}
              handleLinkClick={handleLinkClick}
            />
          </div>
        ) : (
          <div>
            <DesktopHeader categories={categories} />
          </div>
        )}
      </nav>
    </Container>
  );
};

export default Header;
