import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const languages = {
  ar: "ar",
  en: "en",
};

const ChangeLanguageNavItem = () => {
  const homeRoute = useSelector((state) => state.app.homeRoute);
  const { t, i18n } = useTranslation();
  const handleClick = (lng) => {
    i18n.changeLanguage(lng).then(() => {
      console.log(lng);
      if (i18n.language === "en") {
        window.location.replace(
          `${window.location.protocol}//${window.location.host}/${i18n.language}/${homeRoute}`
        );
      } else {
        window.location.replace(
          `${window.location.protocol}//${window.location.host}/${homeRoute}`
        );
      }
    });
  };
  return (
    <>
      <button
        onClick={() =>
          handleClick(
            i18n.language === languages.ar ? languages.en : languages.ar
          )
        }
      >
        {t("header.otherLanguage")}
      </button>
    </>
  );
};

export default ChangeLanguageNavItem;
