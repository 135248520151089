import React from "react";
import Skeleton from "react-loading-skeleton";

const BreadcrumbSkeleton = () => {
  return (
    <div className="flex xs:text-marasi-sm md:text-sm  font-bold gap-x-3 w-1/2">
      {Array(3)
        .fill(true)
        .map((_, index) => {
          return (
            <React.Fragment key={index}>
              <div className="flex-auto">
                <Skeleton />
              </div>
            </React.Fragment>
          );
        })}
    </div>
  );
};

export default BreadcrumbSkeleton;
