import React from "react";
import { Link } from "react-router-dom";
import Container from "../components/Container";
import { useParams } from "react-router-dom";
import { useSearchPostsQuery } from "../features/api/api-slice";
import AppTitle from "../components/AppTitle";
import { useTranslation } from "react-i18next";
import SearchPageSkeleton from "../components/SearchPageSkeleton";

const SearchResults = () => {
  // const { t } = useTranslation('common');
  const { t } = useTranslation();
  const params = useParams();
  const query = params.query;
  const { data: results, isSuccess, isLoading } = useSearchPostsQuery(query);
  let content = undefined;

  if (isLoading) content = <SearchPageSkeleton />;
  if (isSuccess)
    content =
      isSuccess && results.data?.posts?.length > 0 ? (
        <section>
          <div className="header">
            <div>
              <span className="text-marasi-4xl font-semibold">
                ({results.data.posts.length}) {t("search.resultsCount")} "
                {query}"
              </span>
            </div>
            <div className="pt-8">
              <span className="text-2xl font-semibold">
                {t("search.results")}
              </span>
            </div>
          </div>
          <div className="results-list flex flex-col">
            {results.data.posts.map((result) => {
              return <SingleResult key={result.id} result={result} />;
            })}
          </div>
        </section>
      ) : (
        <NoResults text={t("search.noResults")} />
      );

  return (
    <Container>
      <AppTitle>{t("search.results")}</AppTitle>
      {content}
    </Container>
  );
};

const SingleResult = ({ result }) => {
  return (
    <div className="border-b last:border-b-0 py-5">
      <div className="xs:w-full lg:w-5/6">
        <div className="category text-marasi-primary-900 text-sm font-semibold uppercase">
          <Link to={`/${result.category.slug}`}>{result.category.title}</Link>
        </div>
        <div className="title py-2 font-bold text-lg">
          <Link to={`/${result.category.slug}/${result.slug}`}>
            {result.title}
          </Link>
        </div>
        <div className="text-marasi-secondary-400">
          <p>{result.snippet.slice(0, 300)}</p>
        </div>
        <div className="pt-3 text-marasi-secondary-200">
          <span>{result.postDate}</span>
        </div>
      </div>
    </div>
  );
};

const NoResults = ({ text }) => {
  return (
    <div>
      <span className="font-bold">{text}</span>
    </div>
  );
};

export default SearchResults;
