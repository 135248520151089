const toggleScrolling = (
  value = false,
  element = document.getElementsByTagName("html")[0]
) => {
  if (value) {
    element.classList.add("overflow-hidden");
  } else {
    element.classList.remove("overflow-hidden");
  }
};

const copyContentToClipboard = (content) => {
  try {
    navigator.clipboard.writeText(content);
    return true;
  } catch (e) {
    return false;
  }
};

const scrollTo = (x = 0, y = 0) => {
  window.scroll({
    top: y,
    left: x,
    behavior: "smooth",
  });
};

export { toggleScrolling, copyContentToClipboard, scrollTo };
