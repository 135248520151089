import React from "react";
import { useTranslation } from "react-i18next";
import ArticleCard from "../components/ArticleCard";

const RelatedArticles = ({ relatedArticles }) => {
  // const { t } = useTranslation('common');
  const { t } = useTranslation();
  return (
    <div>
      <div className="title pb-8 xs:text-lg xs:font-bold lg:text-xl lg:font-normal">
        <span>{t("categories.relatedArticles")}</span>
      </div>
      <div className="articles flex flex-wrap gap-y-3 -mx-3">
        {relatedArticles.map((article) => {
          return (
            <div className="xs:w-full sm:w-1/2 md:w-1/3 px-3" key={article.id}>
              <ArticleCard
                article={article}
                categoryLink={`/${article.category.slug}`}
                postLink={`/${article.category.slug}/${article.slug}`}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RelatedArticles;
