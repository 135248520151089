import { Link } from "react-router-dom";

import React, { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiSearch } from "react-icons/bi";
import { useSelector, useDispatch } from "react-redux";
import { toggleSearchNavItem, setIsSearchResultsOpen } from "./header-slice";
import { useSearchPostsQuery } from "../api/api-slice";

const SearchNavItem = ({ handleLinkClick }) => {
  const [query, setQuery] = useState("");
  const { data, isSuccess } = useSearchPostsQuery(query);
  const searchResults = isSuccess && data.data.posts;
  // const { t } = useTranslation("common");
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const headerState = useSelector((state) => state.header);
  const searchOpen = headerState.searchNavItemOpen;
  const searchResultsOpen = headerState.isSearchResultsOpen;
  const dispatch = useDispatch();
  const searchNavItemRef = useRef(null);

  const handleChange = (event) => {
    setQuery(event.target.value);
  };

  const handleClick = (event) => {
    event.stopPropagation();
    if (!searchOpen) {
      dispatch(toggleSearchNavItem(!searchOpen));
      inputRef.current.focus();
      dispatch(setIsSearchResultsOpen(!searchResultsOpen));
    }
  };

  const handleClearSearch = () => {
    dispatch(toggleSearchNavItem(false));
    dispatch(setIsSearchResultsOpen(false));
    setQuery("");
  };

  useEffect(() => {
    document.addEventListener("click", (event) => {
      if (event.target !== searchNavItemRef.current)
        dispatch(toggleSearchNavItem(false));
      dispatch(setIsSearchResultsOpen(false));
    });
  }, [searchOpen, dispatch]);

  return (
    <div className="flex items-center w-full">
      <form
        action=""
        method="get"
        className="w-full relative"
        onSubmit={(event) => event.preventDefault()}
      >
        <div
          className={`flex items-center border-b cursor-pointer ${
            searchOpen ? "border-gray-300" : "border-transparent"
          }`}
          onClick={(event) => handleClick(event)}
          ref={searchNavItemRef}
        >
          <div className="px-2 flex items-center">
            <BiSearch className="text-xl " />
          </div>
          <div
            className={`flex transition-all duration-300  overflow-hidden ${
              searchOpen ? "xs:w-full lg:w-80" : "xs:w-full lg:w-0"
            }`}
          >
            <input
              type="text"
              ref={inputRef}
              onChange={(event) => handleChange(event)}
              value={query}
              name=""
              id=""
              placeholder={t("header.searchInput")}
              className={`bg-inherit w-full focus:outline-none px-3 my-2`}
            />
            <div onClick={handleClearSearch} className="xs:hidden lg:block">
              <span className="text-2xl p-2">&times;</span>
            </div>
          </div>
        </div>
        <div
          className={`xs:static lg:absolute xs:shadow-0 lg:shadow-lg top-full bg-white p-4 mt-2 border w-full overflow-hidden z-10 ${
            searchResultsOpen ? "block" : "hidden w-0"
          }`}
        >
          <SearchResults
            searchResults={isSuccess && searchResults}
            status={isSuccess && data.status.success}
            handleLinkClick={handleLinkClick}
            query={query}
          />
        </div>
      </form>
    </div>
  );
};

const SearchResults = ({ searchResults, status, handleLinkClick, query }) => {
  // const { t } = useTranslation('common');
  const { t } = useTranslation();
  let content = undefined;
  if (status & (searchResults?.length > 0)) {
    content = (
      <>
        <div className="header text-marasi-secondary-300">
          <span>{t("header.searchedArticles")}</span>
        </div>
        <div>
          {searchResults.slice(0, 3).map((result) => {
            return (
              <div
                className="results font-bold px-3 my-3 border-b last:border-b-0"
                key={result.id}
              >
                <div className="py-2 truncate" onClick={handleLinkClick}>
                  <Link to={`${result.category.slug}/${result.slug}`}>
                    {result.title}
                  </Link>
                </div>
              </div>
            );
          })}
        </div>

        <div className="footer font-bold text-marasi-secondary-300">
          {query.trim(" ").length > 0 && (
            <Link to={`/search/${query}`} onClick={handleLinkClick}>
              {t("search.seeAllResults")}...
            </Link>
          )}
        </div>
      </>
    );
  } else {
    content = <div>{t("search.noResults")}</div>;
  }

  return <div>{content}</div>;
};

export default SearchNavItem;
