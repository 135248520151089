import { createSlice } from "@reduxjs/toolkit";
import i18next from "../../assets/js/i18next";

const initialState = {
  tabTitle: i18next.t("app.appName"),
  homeRoute: `blog`,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    updateTabTitle: (state, { payload }) => {
      state.tabTitle = payload;
    },
  },
});

export default appSlice.reducer;
