import React from "react";
import Skeleton from "react-loading-skeleton";
import BreadcrumbSkeleton from "./BreadcrumbSkeleton";
import Container from "./Container";
import SingleCategoryArticlesSectionSkeleton from "./SingleCategoryArticlesSectionSkeleton";

const ArticleContentSkeleton = () => {
  return (
    <>
      <div>
        <Container>
          <BreadcrumbSkeleton />
        </Container>
      </div>
      <section className="">
        <Container padding={"pb-0"}>
          <div>
            <div className="category-title pb-4 w-1/12">
              <Skeleton />
            </div>
            <div className="title pb-4 w-3/12">
              <Skeleton />
            </div>
            <div className="hero-image">
              <Skeleton height={300} />
            </div>
          </div>
        </Container>

        <Container padding={"py-2"}>
          <div className="relative">
            <Skeleton />
          </div>
        </Container>
      </section>
      <section>
        <Container padding={"py-2"}>
          <Skeleton />
        </Container>
        <Container padding={"py-2"}>
          <Skeleton height={500} />
          <SingleCategoryArticlesSectionSkeleton />
        </Container>
      </section>
    </>
  );
};

export default ArticleContentSkeleton;
