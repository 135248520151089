import React from "react";
import Skeleton from "react-loading-skeleton";

const SearchPageSkeleton = () => {
  return (
    <section>
      <div className="header">
        <div className="w-2/12">
          <span className="text-marasi-4xl font-semibold">
            <Skeleton />
          </span>
        </div>
        <div className="pt-4 w-4/12">
          <span className="text-2xl font-semibold">
            <Skeleton />
          </span>
        </div>
      </div>
      <div className="results-list flex flex-col">
        {Array(3)
          .fill(3)
          .map((_, index) => {
            return (
              <div className="border-b last:border-b-0 py-5" key={index}>
                <div className="w-full">
                  <div className="category text-marasi-primary-900 text-sm font-semibold uppercase w-2/12">
                    <Skeleton />
                  </div>
                  <div className="title py-2 font-bold text-lg w-5/12">
                    <Skeleton />
                  </div>

                  <div className="pt-3 text-marasi-secondary-200">
                    <span>
                      <Skeleton height={50} />
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </section>
  );
};

export default SearchPageSkeleton;
