import React from "react";

const ShopNow = ({ children }) => {
  return (
    <span className="flex shrink-0 flex-nowrap text-white justify-center bg-marasi-primary-900 border-2 px-4 py-2 border-transparent transition-all duration-500 font-medium hover:bg-white hover:text-marasi-primary-900 hover:border-marasi-primary-900 leading-[initial]">
      {children}
    </span>
  );
};

export default ShopNow;
